<template>
  <div id="AiBookingBar" ref="bookingBar" class="AiBookingBar" role="search">
    <div
      v-if="!isSmallDevice"
      :class="{
        'AiBookingBar--inverse': inverse,
        'AiBookingBar--slim': slim,
      }"
      class="AiBookingBar--desktop">
      <div class="AiBookingBar-inputs">
        <slot name="input-before" />

        <ai-field-dates
          ref="fieldDates"
          v-model="dates"
          :class="{
            'AiBookingBar-datePicker--offset': $slots['input-before'],
          }"
          :disabled="disabled"
          :errors="datesErrors"
          :format="dateFormat"
          :inverse="inverse"
          :label="$t('booking.bar.inputs.date.label')"
          :minimum-stay-duration="minimumStayDuration"
          :name="slugify($t('booking.bar.inputs.date.label'))"
          :placeholder="$t('booking.bar.inputs.date.placeholder')" />

        <ai-field-room-and-guest
          v-model="roomsAndGuests"
          :disabled="disabled"
          :inverse="inverse"
          :label="$t('booking.bar.inputs.roomAndGuest.label')"
          :name="slugify($t('booking.bar.inputs.roomAndGuest.label'))"
          :open-top="sticky && !bottom" />

        <slot name="input-after" />
      </div>

      <slot name="errors" />

      <slot name="action" />
    </div>

    <booking-include-flights-checkbox
      v-if="manageFlights"
      v-model:include-flights="includeFlights"
      :inverse="inverse"
      :slim="slim"
      screen="desktop" />

    <div
      v-else
      id="AiBookingBar--mobile"
      :class="{
        'AiBookingBar--inverse': inverse,
        'AiBookingBar--slim': slim,
      }"
      class="AiBookingBar--mobile">
      <div class="AiBookingBar-mobileContainer">
        <ul class="AiBookingBar-list">
          <li
            v-for="item in mobileItemsBefore"
            :key="item.key"
            class="AiBookingBar-listItem">
            <ai-icon
              v-if="item.icon"
              :class="{
                'AiBookingBar-listItem-icon--inverse': inverse,
              }"
              :description="$t('booking.recap.icons.location')"
              :name="item.icon"
              class="AiBookingBar-listItem-icon" />
            <ai-typo
              :color="inverse ? 'white' : 'default'"
              :variant="
                item.bold ? 'paragraph-04-bold' : 'paragraph-04-regular'
              "
              as="span">
              {{ item.label }}
            </ai-typo>
          </li>

          <li class="AiBookingBar-listItem">
            <ai-icon
              :class="{
                'AiBookingBar-listItem-icon--inverse': inverse,
              }"
              :description="$t('booking.recap.icons.dates')"
              class="AiBookingBar-listItem-icon"
              name="calendar" />
            <ai-typo
              :color="inverse ? 'white' : 'default'"
              :variant="
                datesMobile ? 'paragraph-04-bold' : 'paragraph-04-regular'
              "
              as="span">
              {{ datesMobile ?? $t('booking.bar.mobile.anyDates') }}
            </ai-typo>
          </li>

          <li class="AiBookingBar-listItem">
            <ai-icon
              :class="{
                'AiBookingBar-listItem-icon--inverse': inverse,
              }"
              :description="$t('booking.recap.icons.guestsInfos')"
              class="AiBookingBar-listItem-icon"
              name="guest" />
            <ai-typo
              :color="inverse ? 'white' : 'default'"
              :variant="
                guestsMobile ? 'paragraph-04-bold' : 'paragraph-04-regular'
              "
              as="span">
              {{ guestsMobile ?? $t('booking.bar.mobile.defaultGuests') }}
            </ai-typo>
          </li>

          <li
            v-for="item in mobileItemsAfter"
            :key="item.key"
            class="AiBookingBar-listItem">
            <ai-icon
              v-if="item.icon"
              :class="{
                'AiBookingBar-listItem-icon--inverse': inverse,
              }"
              :name="item.icon"
              class="AiBookingBar-listItem-icon" />
            <ai-typo
              :color="inverse ? 'white' : 'default'"
              :variant="
                item.bold ? 'paragraph-04-bold' : 'paragraph-04-regular'
              "
              as="span">
              {{ item.label }}
            </ai-typo>
          </li>
        </ul>

        <ai-button
          ref="editBookingMobileButton"
          :disabled="disabled"
          data-testid="editBookingMobileButton"
          slim
          variant="tertiary"
          @click="openForm"
          @keyup.enter="openForm">
          <ai-icon
            :class="{ 'AiBookingBar-icon--inverse': inverse }"
            :description="$t('hotel.searchBlock.editBookingMobileButton')"
            :size="32"
            name="edit" />
        </ai-button>

        <booking-include-flights-checkbox
          v-if="manageFlights"
          v-model:include-flights="includeFlights"
          :inverse="inverse"
          :slim="slim"
          screen="mobile" />
      </div>

      <slot name="mobile-after" />

      <ai-booking-bar-mobile-form
        ref="mobileForm"
        v-model="formOpen"
        v-model:dates="dates"
        v-model:include-flights="includeFlights"
        v-model:rooms-and-guests="roomsAndGuests"
        :date-format="dateFormat"
        :dates-errors="datesErrors"
        :has-error="hasError"
        :manage-flights="manageFlights"
        :minimum-stay-duration="minimumStayDuration"
        :rooms-and-guests-errors="roomsAndGuestsErrors"
        :titles="mobileTitles"
        @close="editBookingMobileButton.$el.focus()"
        @submit="$emit('submit')"
        @reset-all="$emit('resetAll')">
        <template #submit>
          <slot name="mobile-submit" />
        </template>

        <template v-if="$slots.errors" #errors>
          <slot name="errors" />
        </template>

        <template
          v-if="$slots['mobile-default-before']"
          #mobile-default-before="slotProps">
          <slot name="mobile-default-before" v-bind="slotProps" />
        </template>

        <template
          v-if="$slots['mobile-default-after']"
          #mobile-default-after="slotProps">
          <slot name="mobile-default-after" v-bind="slotProps" />
        </template>

        <template
          v-for="(_, slotName) in $slots"
          :key="slotName"
          #[slotName]="slotProps">
          <slot :name="slotName" v-bind="slotProps" />
        </template>
      </ai-booking-bar-mobile-form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { differenceInCalendarDays, format } from 'date-fns';

import { event, slugify } from '~/helpers';

import type { GenericIconName } from '../../atoms/AiIcon/types';
import type AiFieldDates from '../AiForm/AiFieldDates.vue';
import type AiFieldRoomAndGuest from '../AiForm/AiFieldRoomAndGuest.vue';

import type AiBookingBarMobileForm from './AiBookingBarMobileForm.vue';

export type MobileItem = {
  position: 'before' | 'after';
  key: string;
  label: string;
  icon?: GenericIconName;
  bold?: boolean;
};

type Props = {
  bottom?: boolean;
  dateFormat?: string;
  dates: Date[];
  datesErrors?: string[];
  disabled?: boolean;
  includeFlights?: boolean;
  inverse?: boolean;
  manageFlights?: boolean;
  minimumStayDuration?: number;
  mobileItems?: MobileItem[];
  mobileShowRoomCountOnDates?: boolean;
  mobileTitles?: Record<string, string>;
  roomsAndGuests: { adults: number; childrenAges: number[] }[];
  roomsAndGuestsErrors?: { roomIndex: number; childrenAges: number[] }[];
  slim?: boolean;
  sticky?: boolean;
  variant?: 'default' | 'inverse';
};
const props = withDefaults(defineProps<Props>(), {
  dateFormat: 'dd LLL',
  datesErrors: undefined,
  includeFlights: false,
  manageFlights: false,
  minimumStayDuration: undefined,
  mobileItems: () => [],
  mobileTitles: () => ({}),
  roomsAndGuestsErrors: () => [],
  variant: 'default',
});

type Emits = {
  (event: 'resetAll'): void;
  (event: 'submit'): void;
  (event: 'update:dates', value: Props['dates']): void;
  (event: 'update:includeFlights', value: boolean): void;
  (event: 'update:rooms-and-guests', value: Props['roomsAndGuests']): void;
};
const emits = defineEmits<Emits>();

const isSmallDevice = useMatchMediaQuery('tablet');
const editBookingMobileButton = ref();
const fieldDates = ref();

const slots = useSlots();

const hasError = computed(() => Boolean(slots.errors));

const dates = computed({
  get() {
    return props.dates;
  },
  set(value: Props['dates']) {
    emits('update:dates', value);
  },
});

const roomsAndGuests = computed({
  get() {
    return props.roomsAndGuests;
  },
  set(value: Props['roomsAndGuests']) {
    emits('update:rooms-and-guests', value);
  },
});

const includeFlights = computed({
  get() {
    return props.includeFlights;
  },
  set(value: boolean) {
    emits('update:includeFlights', value);
  },
});

// Mobile related below

const { t } = useI18n();
const formOpen = ref(false);

const dateFnsLocale = useDateFnsLocale();

const datesMobile = computed(() => {
  if (!Array.isArray(props.dates) || props.dates.length < 2) return;

  const [rawFirstDate, rawLastDate] = props.dates;

  const firstDate = props.dateFormat
    ? format(rawFirstDate, props.dateFormat, { locale: dateFnsLocale.value })
    : rawFirstDate.toString();
  const lastDate = props.dateFormat
    ? format(rawLastDate, props.dateFormat, { locale: dateFnsLocale.value })
    : rawLastDate.toString();

  if (!props.mobileShowRoomCountOnDates)
    return t('booking.bar.mobile.dates', { firstDate, lastDate });

  const numberOfNights = Math.abs(
    differenceInCalendarDays(rawFirstDate, rawLastDate),
  );

  return t('booking.bar.mobile.datesWithCount', numberOfNights, {
    named: {
      count: numberOfNights,
      firstDate,
      lastDate,
    },
  });
});

const guestsMobile = computed(() => {
  const rooms = props.roomsAndGuests.length;
  const guests = props.roomsAndGuests.reduce(
    (sum, room) => sum + room.adults + room.childrenAges.length,
    0,
  );

  if (!rooms || !guests) return;

  const roomsLabel = t('booking.bar.inputs.roomAndGuest.room', {
    count: rooms,
  });

  const guestsLabel = t('booking.bar.inputs.roomAndGuest.guest', {
    count: guests,
  });

  return t('booking.bar.inputs.roomAndGuest.fullValue', {
    guests: guestsLabel,
    rooms: roomsLabel,
  });
});

const mobileItemsBefore = computed(() =>
  props.mobileItems.filter(item => item.position === 'before'),
);
const mobileItemsAfter = computed(() =>
  props.mobileItems.filter(item => item.position === 'after'),
);

const mobileForm = ref<typeof AiBookingBarMobileForm>();

const closeMobileForm = () => {
  mobileForm.value?.setPage(undefined);
  formOpen.value = false;
};
const openMobileForm = () => (formOpen.value = true);
const isMobileFormOpen = computed(() => formOpen.value);
const setPage = computed(() => mobileForm.value?.setPage);
const bookingBar = ref<HTMLDivElement>();

// Open datepicker functions
const toDesktopDevice = useMatchMediaQuery('desktop');

const openDatepicker = () => {
  if (toDesktopDevice.value) {
    openMobileDatepicker();
  } else {
    openDesktopDatepicker();
  }
};

const openMobileDatepicker = () => {
  setPage.value('dates');
  openMobileForm();
};

const openDesktopDatepicker = () => {
  if (!fieldDates.value) return;

  fieldDates.value.openDatepicker();
};

defineExpose({
  isMobileFormOpen,
  closeMobileForm,
  element: bookingBar,
  openMobileForm,
  setPage,
  openDatepicker,
});

const openForm = () => {
  formOpen.value = true;

  event('click_cta_show_booking_form', {
    category: 'search',
    action: 'click cta show booking form',
    label: 'simplified form',
  });
};
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/functions' as func;

.AiBookingBar {
  width: 100%;
}

.AiBookingBar--desktop {
  display: none;
  background-color: colors.$gold-100;
  padding: constants.$padding-03;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.AiBookingBar--inverse {
    background-color: transparent;
  }

  &.AiBookingBar--slim {
    padding: 0;
  }

  @media (mq.$from-large) {
    display: flex;
  }
}

.AiBookingBar-inputs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: func.calcRem(32);
}

.AiBookingBar--mobile {
  background-color: colors.$gold-100;
  padding-top: constants.$padding-02;
  padding-bottom: constants.$padding-02;
  padding-left: constants.$padding-01;
  padding-right: constants.$padding-01;

  &.AiBookingBar--inverse {
    background-color: transparent;
  }

  &.AiBookingBar--slim {
    padding: 0;
  }

  @media (mq.$from-large) {
    display: none;
  }
}

.AiBookingBar-mobileContainer {
  display: grid;
  grid-template-columns: 1fr auto;
}

.AiBookingBar-list {
  flex-grow: 1;
}

.AiBookingBar-listItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: constants.$inner-01;
  }
}

.AiBookingBar-icon--inverse {
  color: colors.$gold-300;
}

.AiBookingBar-listItem-icon {
  margin-right: constants.$margin-00;
  color: colors.$gold-900;

  &--inverse {
    color: colors.$gold-300;
  }
}
</style>
