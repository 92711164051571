<template>
  <ai-input-with-selector
    v-model="rooms"
    :label="$t('booking.bar.inputs.roomAndGuest.roomLabel')"
    :min="1"
    :max="3"
    class="AiRoomAndGuestForm-rooms"
    @update:model-value="scrollToBottom" />

  <div
    v-for="(room, index) of guests"
    :key="`room-${index}`"
    class="AiRoomAndGuestForm-guests">
    <ai-typo as="label" variant="overline-02" color="stratos-500">
      {{
        $t('booking.bar.inputs.roomAndGuest.roomTitle', {
          index: index + 1,
          nbRooms: rooms,
        })
      }}
    </ai-typo>

    <!-- Adult selector -->
    <ai-input-with-selector
      data-testid="roomAndGuestFormAdults"
      :model-value="room.adults"
      :subtitle="
        $t('booking.bar.inputs.roomAndGuest.roomTitle', {
          index: index + 1,
          nbRooms: rooms,
        })
      "
      :label="$t('booking.bar.inputs.roomAndGuest.adults')"
      :min="1"
      :max="9"
      @update:model-value="count => updateAdultRoom(room, count, index)" />

    <ai-separator :margin="0" />

    <!-- Number of children selector -->
    <ai-input-with-selector
      data-testid="roomAndGuestFormChildrenAges"
      :model-value="room.childrenAges?.length || 0"
      :index="index"
      :subtitle="
        $t('booking.bar.inputs.roomAndGuest.roomTitle', {
          index: index + 1,
          nbRooms: rooms,
        })
      "
      :label="$t('booking.bar.inputs.roomAndGuest.children')"
      :min="0"
      :max="9"
      @update:model-value="count => updateRoom(room, count, index)" />

    <!-- Children age selectors -->
    <div
      v-if="Array.isArray(room.childrenAges) && room.childrenAges.length > 0"
      class="AiRoomAndGuestForm-childrenAges"
      :class="'AiRoomAndGuestForm-childrenAges--' + index">
      <ai-field-select
        v-for="(_children, childIndex) in room.childrenAges"
        :key="`room-${index}-child-${childIndex}`"
        v-model="room.childrenAges[childIndex]"
        :name="`children-selector-` + childIndex"
        :options="childAgeOptions"
        :errors="
          childrenAgeErrors?.[index]?.[childIndex] && showChildrenAgeErrors
            ? [$t('booking.bar.inputs.roomAndGuest.childAgeRequired')]
            : undefined
        "
        :label="
          $t('booking.bar.inputs.roomAndGuest.ageOfChild', {
            index: childIndex + 1,
          })
        "
        @update:model-value="
          if (Array.isArray(room.childrenAges)) {
            updateRoom(room, room.childrenAges.length, index);
          }
        " />
    </div>
  </div>
</template>

<script setup lang="ts">
import AiTypo from '../../atoms/AiTypo/AiTypo.vue';
import AiSeparator from '../../atoms/AiSeparator/AiSeparator.vue';
import AiFieldSelect from '../../molecules/AiForm/AiFieldSelect.vue';
import AiInputWithSelector from '../../molecules/AiForm/AiInputWithSelector.vue';

type Guests = {
  adults: number;
  childrenAges?: (number | null)[];
};

type Props = {
  modelValue: Guests[];
  childrenAgeErrors?: boolean[][];
  showChildrenAgeErrors?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: Props['modelValue']): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const guests = computed({
  get() {
    if (Array.isArray(props.modelValue) && props.modelValue.length > 0)
      return props.modelValue;

    return [{ adults: 2, childrenAges: [] }];
  },
  set(value: Props['modelValue']) {
    emits('update:modelValue', value);
  },
});

const rooms = computed({
  get() {
    if (!Array.isArray(guests.value)) return 0;

    return guests.value.length;
  },
  set(value: number) {
    const tempGuests = Array.isArray(guests.value) ? [...guests.value] : [];

    if (value < tempGuests.length) {
      tempGuests.splice(value, tempGuests.length);
    } else {
      const roomsToAdd = value - tempGuests.length;

      for (let i = 0; i < roomsToAdd; i++) {
        tempGuests.push({ adults: 1, childrenAges: [] });
      }
    }

    emits('update:modelValue', tempGuests);
  },
});

const updateAdultRoom = (roomConfig: Guests, adults: number, index: number) => {
  const room = { ...roomConfig };

  room.adults = adults;

  const allGuests = [...guests.value];
  allGuests[index] = room;

  guests.value = allGuests;
};

const updateRoom = (roomConfig: Guests, count: number, index: number) => {
  const room = { ...roomConfig };

  if (!Array.isArray(room.childrenAges)) room.childrenAges = [];

  if (count < room.childrenAges.length) {
    room.childrenAges.splice(count, room.childrenAges.length);
  } else {
    const childrensToAdd = count - room.childrenAges.length;

    for (let i = 0; i < childrensToAdd; i++) {
      room.childrenAges.push(null);
    }

    nextTick(() => {
      requestAnimationFrame(() => {
        const node = document.querySelector(
          '.AiRoomAndGuestForm-childrenAges--' + index,
        );

        const container = node?.parentNode?.parentElement;

        if (!node?.getBoundingClientRect().height) return;

        container?.scrollBy({
          behavior: 'smooth',
          top: node?.getBoundingClientRect().height * index,
        });
      });
    });
  }

  const allGuests = [...guests.value];
  allGuests[index] = room;

  guests.value = allGuests;
};

const scrollToBottom = () => {
  nextTick(() => {
    requestAnimationFrame(() => {
      const node = document.querySelector('#AiFieldRoomAndGuest-dropdown');

      node?.scrollBy({
        behavior: 'smooth',
        top: node.scrollHeight,
      });
    });
  });
};

const { t } = useI18n();

const childAgeOptions = computed(() => {
  const options = [];

  for (let i = 0; i < 17; i++) {
    options.push({
      label: t('booking.bar.inputs.roomAndGuest.ageOfChildOption', {
        count: i,
      }),
      value: i,
    });
  }

  return options;
});
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';

.AiRoomAndGuestForm-rooms {
  margin-bottom: constants.$margin-02;
}

.AiRoomAndGuestForm-childrenAges {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: constants.$inner-05;
  margin-top: constants.$margin-01;
}

.AiRoomAndGuestForm-guests:not(:first-of-type) {
  margin-top: constants.$margin-03;
}
</style>
